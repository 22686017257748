import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';

import meImage from '../assets/images/me.jpg';

const PortraitContainer = styled.div`
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: row;

    @media (min-width: 768px) {
        margin-right: 20px;
        float: left;
    }

    @media (max-width: 768px) {
        margin-bottom: 15px;
    }

    & img {
        border-radius: 50%;
    }
`;

const SubTitle = styled.h2`
    clear: both;
`;

export default props => {
    return (
        <Layout location={props.location}>
            <h1 className="major">À propos</h1>

            <PortraitContainer>
                <img src={meImage} alt="Portrait of myself" />
            </PortraitContainer>

            <p>
                Je m’appelle Guillaume Pannetier, je suis originaire de
                Normandie et je suis photographe professionnel à Montpellier. Je
                suis spécialisé dans le portrait lifestyle ou studio et le
                reportage.
                <br />
                <br />
                J'ai fait mes premiers pas en photographie lors de mon séjour
                Erasmus en Suède en 2011. Quand mes parents, eux-même
                photographes amateurs et passionnés, sont venus me rendre visite
                et m’ont initié à la composition d’image et à l’influence de
                chaque réglage de l’appareil sur le rendu final, ça a été une
                révélation pour moi.
            </p>
            <p>
                De retour en France, j’ai acheté mon premier appareil reflex. Je
                me suis naturellement tourné vers le portrait, en lumière
                naturelle, au flash ou en mixant les deux. J’ai appris les
                techniques de bases du portrait dans des livres, puis je me suis
                perfectionné auprès de professionnels. Je suis quelqu’un qui a
                besoin d’une bonne maîtrise technique avant d'être créatif,
                c’est pourquoi je suis constamment en train de chercher à mieux
                comprendre la lumière.
            </p>
            <p>
                Bien que je travaille en numérique, j’aime le côté organique du
                grain des pellicules argentiques, que j’ajoute parfois dans mon
                traitement. Je suis également danseur et la musique fait partie
                intégrante de ma vie. Je l'utilise beaucoup en photographie
                aussi, pour mettre le modèle à l'aise ou pour me mettre dans une
                ambiance inspirante.
            </p>
        </Layout>
    );
};
